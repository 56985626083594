import matchtitle from '../../components/matchtitle';
import {

	Message
} from 'element-ui'
import {
	handlePageQuery,
	handleDeleteScoreTable,
	handleQueryTeamOfMatch
} from '@index/api/mymatchs/scoretable';

export default {
	name: 'scoretable',
	components: {
		matchtitle
	},
	data() {
		return {
			fullscreenLoading:false,
			havAuth:false,
			headers: {
				'Authorization': sessionStorage.getItem('token')
			},
			path: process.env.VUE_APP_BASE_API + "scoreTable/upload",
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			queryForm: {},
			tableData: [],
			teamsInfo: [],
			matchId: sessionStorage.getItem("currMatchId"),
			uploadFileParams: {
				matchId: sessionStorage.getItem("currMatchId")
			},
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
		this.queryTeamOfMatch();
	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth=true;
		},
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQuery({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: this.queryForm.con,
				team:this.queryForm.team,
				matchId: sessionStorage.getItem("currMatchId"),
				sortWay: [{
					fieldName: 'orderNo',
					sortWay: 'ASC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		uploadSuccess(res) { //上传成功
			this.fullscreenLoading=false;
			this.$refs.upload.clearFiles();
		if (res.status != 200) {
			Message({
				message: '上传失败!',
				type: 'error'
			});
			return;
		}
			Message({
				message: '上传成功!',
				type: 'success',
				duration: 5 * 1000
			});
			this.currPage = 1;
			this.queryForm.con = "";
			this.pageQuery();
			
		
		},
		beforeUpload(){
			this.fullscreenLoading=true;
			
		},
		uploadError(){
			this.fullscreenLoading=false;
		},
		deleteScoreTable() {
			this.$confirm('此操作将永久删除该数据件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'confirmButtonClass',
				type: 'warning'
			}).then(() => {
				handleDeleteScoreTable(this.matchId).then(res => {
					if (res.status == 200) {
						Message({
							message: res.msg,
							type: 'success',
							duration: 5 * 1000
						});
						this.pageQuery();
					} else {
						Message({
							message: res.msg,
							type: 'error',
							duration: 5 * 1000
						})
					}
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		queryTeamOfMatch() {
			handleQueryTeamOfMatch(this.matchId).then(res => {
				if (res.status == 200) {
					console.log(">>res.data>>>>"+JSON.stringify(res.data));
					this.teamsInfo = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		}

	}
}
